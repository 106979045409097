document.addEventListener("DOMContentLoaded", function () {
  const map = L.map('map', {
    center: [43.651070, -79.347015],
    zoom: 10,
    zoomControl: false,
    dragging: false,
    scrollWheelZoom: false,
    doubleClickZoom: false,
    boxZoom: false,
    keyboard: false
  });

  L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
    attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
  }).addTo(map);

  const locations = {
    "Downtown": [43.6548, -79.3883],
    "North York": [43.7711, -79.4136],
    "Midtown": [43.6978, -79.3974],
    "East York": [43.6896, -79.3297],
    "Etobicoke": [43.6541, -79.5671],
    "Scarborough": [43.7731, -79.2578],
    "Vaughan": [43.8563, -79.5085],
    "Toronto": [43.651070, -79.347015],
    "Newmarket": [44.0592, -79.4613],
    "Ajax": [43.8509, -79.0204],
    "Oakville": [43.4675, -79.6877],
    "Mississauga": [43.5890, -79.6441],
    "Milton": [43.5183, -79.8774],
    "Barrie": [44.3894, -79.6903]
  };

  const markers = {};

  for (const [location, coords] of Object.entries(locations)) {
    const icon = L.icon({
      iconUrl: '../img/pin.png',
      iconSize: [30, 30],
      iconAnchor: [15, 30],
      popupAnchor: [0, -30]
    });

    const marker = L.marker(coords, { icon }).addTo(map).bindPopup(location);
    markers[location] = marker;
  }

  document.querySelectorAll('.area__item').forEach(item => {
    item.addEventListener('click', () => {
      const location = item.getAttribute('data-location');
      const marker = markers[location];
      if (marker) {
        const currentIcon = marker.getIcon();
        const newIcon = L.icon({
          iconUrl: '../img/pin.png',
          iconSize: [currentIcon.options.iconSize[0] * 1.5, currentIcon.options.iconSize[1] * 1.5],
          iconAnchor: [currentIcon.options.iconAnchor[0] * 1.5, currentIcon.options.iconAnchor[1] * 1.5],
          popupAnchor: [currentIcon.options.popupAnchor[0], currentIcon.options.popupAnchor[1] * 1.5]
        });

        marker.setIcon(newIcon);
        map.setView(marker.getLatLng(), map.getZoom());
      }
    });
  });
});
