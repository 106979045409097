import './components/burger.js';
import './components/preloader.js';
// import './components/active-link.js';
import './components/disable-scroll.js';
import './components/enable-scroll.js';
import './components/fixed-header.js';
import './components/header-height.js';
import './components/counter.js';
import './components/resize-slider.js';
import './components/slider.js';
import './components/tabs.js';
import './components/hidden-input.js';
import './components/custom-select.js';
import './components/map.js';
import './components/aos.js';
