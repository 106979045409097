const inputs = document.querySelectorAll('.form__input');
const icons = document.querySelectorAll('.form__input-icon');

inputs.forEach((input, index) => {
  input.addEventListener('focus', () => {
    icons[index].classList.add('hidden');
  });

  input.addEventListener('blur', () => {
    if (!input.value) {
      icons[index].classList.remove('hidden');
    }
  });

  input.addEventListener('input', () => {
    if (!input.value) {
      icons[index].classList.remove('hidden');
    } else {
      icons[index].classList.add('hidden');
    }
  });
});
