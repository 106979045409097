var scrolled = false;

window.addEventListener('scroll', function() {
  if (!scrolled && isInViewport(document.querySelector('.features__list'))) {
    scrolled = true;
    animateCounters();
  }
});

function animateCounters() {
  var counters = document.querySelectorAll('.number');

  counters.forEach(function(counter) {
    var value = parseInt(counter.getAttribute('data-value'));
    var duration = 2000; // Duration for the counter animation (in milliseconds)
    var startTime = null;

    function step(timestamp) {
      if (!startTime) startTime = timestamp;
      var progress = timestamp - startTime;
      var increment = Math.floor(value * (progress / duration));
      counter.textContent = increment.toLocaleString();

      if (progress < duration) {
        window.requestAnimationFrame(step);
      } else {
        counter.textContent = value.toLocaleString();
      }
    }

    window.requestAnimationFrame(step);
  });
}

// Function to check if an element is in the viewport
function isInViewport(element) {
  var rect = element.getBoundingClientRect();
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
}
