var swiper = new Swiper(".reviews__slider", {
  pagination: {
    el: '.custom-progressbar',
    type: 'progressbar',
    renderProgressbar: function (progressbarFillClass) {
      return '<div class="' + progressbarFillClass + ' custom-progress"></div>';
    },
  },
  navigation: {
    nextEl: ".reviews__nav-btn--next",
    prevEl: ".reviews__nav-btn--prev",
  },
  slidesPerView: 1,
  spaceBetween: 15,
  effect: "slide",
  speed: 500,
  breakpoints: {
    500: {
      slidesPerView: 1.2,
    },
    600: {
      slidesPerView: 2,
      spaceBetween: 30,
    },
    600: {
      slidesPerView: 3,
      spaceBetween: 30,
    },
    900: {
      slidesPerView: 4,
      spaceBetween: 30,
    },
    1024: {
      slidesPerView: 4.5,
      spaceBetween: 30,
    },
  },
});


var allSwiper = new Swiper(".all__slider", {
  slidesPerGroup: 1,
  slidesPerView: 1,
  freeMode: true,
  effect: "slide",
  speed: 500,
  grid: {
    rows: 2,
    fill: 'row',
  },
  spaceBetween: 10,
  observer: true,
  observeParents: true,
  navigation: {
    nextEl: document.querySelector(".all--next"),
    prevEl: document.querySelector(".all--prev"),
  },
  breakpoints: {
    500: {
      slidesPerView: 1.2,
      grid: {
        rows: 2,
        fill: 'row',
      },
    },
    768: {
      slidesPerView: 2.8,
      spaceBetween: 30,
      grid: {
        rows: 2,
        fill: 'row',
      },
    },
  },
});

var bnbSwiper = new Swiper(".bnb__slider", {
  slidesPerGroup: 1,
  slidesPerView: 1,
  freeMode: true,
  effect: "slide",
  speed: 500,
  grid: {
    rows: 2,
    fill: 'row',
  },
  spaceBetween: 10,
  observer: true,
  observeParents: true,
  navigation: {
    nextEl: document.querySelector(".bnb--next"),
    prevEl: document.querySelector(".bnb--prev"),
  },
  breakpoints: {
    500: {
      slidesPerView: 2.2,
      grid: {
        rows: 2,
        fill: 'row',
      },
    },
    768: {
      slidesPerView: 2.8,
      spaceBetween: 30,
      grid: {
        rows: 2,
        fill: 'row',
      },
    },
  },
});

var leaningSwiper = new Swiper(".leaning__slider", {
  slidesPerGroup: 1,
  slidesPerView: 1,
  freeMode: true,
  effect: "slide",
  speed: 500,
  grid: {
    rows: 2,
    fill: 'row',
  },
  spaceBetween: 10,
  observer: true,
  observeParents: true,
  navigation: {
    nextEl: document.querySelector(".leaning--next"),
    prevEl: document.querySelector(".leaning--prev"),
  },
  breakpoints: {
    500: {
      slidesPerView: 2.2,
      grid: {
        rows: 2,
        fill: 'row',
      },
    },
    768: {
      slidesPerView: 2.8,
      spaceBetween: 30,
      grid: {
        rows: 2,
        fill: 'row',
      },
    },
  },
});

var upholsterySwiper = new Swiper(".upholstery__slider", {
  slidesPerGroup: 1,
  slidesPerView: 1,
  freeMode: true,
  effect: "slide",
  speed: 500,
  grid: {
    rows: 2,
    fill: 'row',
  },
  spaceBetween: 10,
  observer: true,
  observeParents: true,
  navigation: {
    nextEl: document.querySelector(".upholstery--next"),
    prevEl: document.querySelector(".upholstery--prev"),
  },
  breakpoints: {
    500: {
      slidesPerView: 2.2,
      grid: {
        rows: 2,
        fill: 'row',
      },
    },
    768: {
      slidesPerView: 2.8,
      spaceBetween: 30,
      grid: {
        rows: 2,
        fill: 'row',
      },
    },
  },
});

var windowSwiper = new Swiper(".window__slider", {
  slidesPerGroup: 1,
  slidesPerView: 1,
  freeMode: true,
  effect: "slide",
  speed: 500,
  grid: {
    rows: 2,
    fill: 'row',
  },
  spaceBetween: 10,
  observer: true,
  observeParents: true,
  navigation: {
    nextEl: document.querySelector(".window--next"),
    prevEl: document.querySelector(".window--prev"),
  },
  breakpoints: {
    500: {
      slidesPerView: 2.2,
      grid: {
        rows: 2,
        fill: 'row',
      },
    },
    768: {
      slidesPerView: 2.8,
      spaceBetween: 30,
      grid: {
        rows: 2,
        fill: 'row',
      },
    },
  },
});

var balconySwiper = new Swiper(".balcony__slider", {
  slidesPerGroup: 1,
  slidesPerView: 1,
  freeMode: true,
  effect: "slide",
  speed: 500,
  grid: {
    rows: 2,
    fill: 'row',
  },
  spaceBetween: 10,
  observer: true,
  observeParents: true,
  navigation: {
    nextEl: document.querySelector(".balcony--next"),
    prevEl: document.querySelector(".balcony--prev"),
  },
  breakpoints: {
    500: {
      slidesPerView: 2.2,
      grid: {
        rows: 2,
        fill: 'row',
      },
    },
    768: {
      slidesPerView: 2.8,
      spaceBetween: 30,
      grid: {
        rows: 2,
        fill: 'row',
      },
    },
  },
});
